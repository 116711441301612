// HEADER
header{
    margin: 30px 0 80px 0;
    background-color: #433a9b;
    position: static;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    @media(max-width:991px){
       padding: 10px 0;
    }
    .container{
        position: relative;
        justify-content: flex-end;
        @media(max-width:991px){
            justify-content: space-between;
        }
        .logo{
            position: absolute;
            left: 0;
            top: 10px;
            @media(max-width:1199px){
                width: 250px;
            }
            @media(max-width:991px){
                width: 160px;
                position: static;
            }
        }
        .navmenu{
            display: flex;
            align-items: center;
            @media(max-width:991px){
                position: fixed;
                left: -100%;
                top: 0;
                display: block;
                width: 250px;
                padding: 20px;
                background: #433a9b;
                height: 100%;transition: 0.3s all ease-in-out 0s;
            }
            .menu{
                display: flex;
                @media(max-width:991px){
                    flex-wrap: wrap;
                    padding-top: 30px;
                }
                li{
                    @media(max-width:991px){
                        width: 100%;
                        margin: 0;
                    }
                    a{
                        font-size: 14px;
                        color: #ffffff;
                        text-transform: uppercase;
                        padding: 18px 12px 17px 12px;
                        margin: 0 20px;
                        display: block;
                        position: relative;                     
                        @media(max-width:1199px){
                            padding: 15px 8px 14px 8px;
                            margin: 0 10px;
                        }
                        @media(max-width:991px){
                            margin: 0; 
                            width: 100%;
                            padding: 5px;
                            font-size: 20px;
                            line-height: 24px;
                            margin-bottom: 12px;
                        }
                            &::before{
                                content: '';
                                position: absolute;
                                left: -24px;
                                top: 0;
                                background: url('../assets/images/navleft.png') no-repeat 0 0;
                                height: 100%;
                                width: 24px;
                                background-size: 100% 100%;
                                display: none;
                            }
                            &::after{
                                content: '';
                                position: absolute;
                                right: -54px;
                                top: 0;
                                background: url('../assets/images/navright.png') no-repeat 0 0;
                                height: 100%;
                                width: 54px;
                                background-size: 100% 100%;
                                display: none;
                                @media(max-width:1199px){
                                    right: -44px;
                                    width: 44px;
                                }
                            }
                        &:hover,&.active{
                            background: #191818;
                            &::after{
                                display: block;
                                @media(max-width:991px){
                                    display: none;
                                }
                            }
                            &::before{display: block;
                                @media(max-width:991px){
                                    display: none;
                                }
                            }

                        }
                    }
                        
                }
            }
            .social{
                display: flex;
                margin-left: 30px;
                 @media(max-width:1199px){
                     margin-left: 10px;
                 }  
                 @media(max-width:991px){
                    margin-left: 0;
                    margin-top: 20px;
                }    
                li{
                    margin-right: 30px;
                    @media(max-width:991px){
                        margin-right: 10px;
                    }
                    &:first-child{
                        a{background-position: 0px 3px;}
                    }
                    &:nth-child(2){
                        a{background-position: -50px 3px;}
                    }
                    &:last-child{
                        margin-right: 0;
                        a{background-position: -100px 2px;}
                    }
                    a{
                        background: url('../assets/images/social.png') no-repeat 0 0;
                        width: 20px;
                        height: 20px;
                        display: block;
                    }
                }
            }
        }
    }
}
.body-active{
    header{
        .container{
             .navmenu{
                 left: 0;
                 z-index: 999;
             }
        }
    }
}

// SECTION 1
.section1{
    margin: 0 0 35px;
    margin-top: 178px;
    @media(max-width:991px){
        margin-top: 0;
    }
    .container{
        flex-direction: column;
        h2{
            text-transform: uppercase;
            font-size: 26px;
            letter-spacing: 2px;
            text-align: center;
        }
        .subText {
            font-size: 20px;
            text-align: center;
            font-family: 'Poppins';
            font-weight: 300;
            margin: 0 0 50px;
        }
        .content{
            display: flex;
            justify-content: space-between;
            @media(max-width:767px){
                flex-wrap: wrap;
            }
            .left{
                padding:50px 45px 50px 0;
                border-right: 3px solid #313131;
                width: 50%;
                position: relative;
                display: flex;
                flex-direction: column;
                @media(max-width:767px){
                    width: 100%;
                    padding:0 0 25px 0;
                border-right: 0;
                border-bottom: 3px solid #313131 ;
                }
                &:before{
                    width: 6px;
                    height: 6px;
                    border-radius: 100%;
                    content: '';
                    position: absolute;
                    right: -6px;
                    top: -2px;
                    background: #313131;
                    @media(max-width:767px){
                        display: none;
                    }
                }
                h3{
                    align-self: flex-end;
                    background: url('../assets/images/studios.png') no-repeat 0 0;
                    @media(max-width:767px){
                        align-self: flex-start;
                    }
                }
                .subText{
                    text-align: right;
                    align-self: flex-end;
                    color: #f2f0f0;
                    @media(max-width:767px){
                        align-self: flex-start;
                        text-align: left;
                        margin-bottom: 20px;
                    }
                }
            }
            .right{
                padding:50px 0 50px 45px;
                border-left: 3px solid #313131;
                width: 50%;
                position: relative;
                @media(max-width:767px){
                    width: 100%;
                    padding:25px 0;
                border-right: 0;
                border-left:0 ;
                }
                &:before{
                    width: 6px;
                    height: 6px;
                    border-radius: 100%;
                    content: '';
                    position: absolute;
                    left: -6px;
                    bottom: -2px;
                    background: #313131;
                    @media(max-width:767px){
                        display: none;
                    }
                }
                h3{
                    align-self: flex-start;
                    background: url('../assets/images/defi.png') no-repeat 100% 0;
                    padding: 25px 130px 25px 0;
                    @media(max-width:991px){
                        padding: 25px 90px 25px 0;
                    }
                }
                .subText{
                    text-align: left;
                    align-self: flex-start;
                    color: #f2f0f0;
                }
            }
            h3{
                font-size: 36px;
                text-transform: uppercase;
                margin: 0 0 10px;
                padding: 25px 0 25px 130px;
                display: inline-block;
                @media(max-width:991px){
                    background-size: 60px !important;
                    padding: 25px 0 25px 90px;
                    font-size: 26px;
                }
                span{
                    color: #f58a1f;
                }
            }
        }
    }
}

// SECTION 2
.section2{
    margin-bottom: 45px;
    .container{
        flex-direction: column;
        border-bottom: 1px solid #2d2d2d;
        position: relative;
        @media(max-width:991px){
            flex-wrap: wrap;
        }
        .anime{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            hr{
                margin-top: 130px;
                border: 3px solid #2d2d2d;
                @media(max-width:991px){
                    display: none;
                }
            }
            @media(max-width:991px){
                position: static;
                margin: 40px 0;
                transform: inherit;
                flex-basis: 100%;
                 max-width: 100%;
            }
        }
        h2{
            font-size: 48px;
            margin: 0 0 55px;
            padding: 0 180px;
            position: relative;
            text-transform: uppercase;
            align-self: center;
            @media(max-width:767px){
                padding: 0;
            }
            @media(max-width:479px){
                font-size: 35px;
            }
            &:before{
                content: '';
                width: 120px;
                height: 55px;
                border-top: 5px solid #2d2d2d;
                border-left: 5px solid #2d2d2d;
                border-top-left-radius: 10px;
                position: absolute;
                left: 0;
                top: 25px;
                @media(max-width:991px){display: none;}
            }
            &:after{
                content: '';
                width: 120px;
                height: 55px;
                border-top: 5px solid #2d2d2d;
                border-right: 5px solid #2d2d2d;
                border-top-right-radius: 10px;
                position: absolute;
                right: 0;
                top: 25px;
                @media(max-width:991px){display: none;}
            }
        }
        .content{
            display: flex;
            justify-content: space-between;
            @media(max-width:991px){
                flex-basis: 100%;
                max-width: 100%;
                flex-wrap: wrap;
            }
            &:last-child{
                .left{
                    padding-bottom: 20px;
                    &::before{
                        display: none;
                    }
                }
                .right{
                    padding-bottom: 20px;
                    &::before{
                        display: none;
                    }
                }
            }
            .left{
                width: 30%;
                padding: 0 0 80px;
                position: relative;
                @media(max-width:991px){
                  width: 100%;
                  margin-bottom: 30px; padding: 0 0 0;
                }
                &:before{
                    content: '';
                    position: absolute;
                    right: 60px;
                    bottom: 30px;
                    height: 40px;
                    width: 5px;
                    background: #2d2d2d;
                }
                h3{
                    font-size: 30px;
                    text-align: right;
                    text-transform: uppercase;
                    @media(max-width:991px){
                        text-align: left;
                    }
                    @media(max-width:479px){
                        font-size: 25px;
                        
                    }
                }
            }
            .right{
                width: 30%;
                padding: 0 0 80px;
                position: relative;
                @media(max-width:991px){
                    width: 100%;
                    padding: 0 0 0;
                  }
                &:before{
                    content: '';
                    position: absolute;
                    left: 60px;
                    bottom: 30px;
                    height: 40px;
                    width: 5px;
                    background: #2d2d2d;
                }
                h3{
                    font-size: 30px;
                    text-align: left;
                    text-transform: uppercase;
                }
            }
        }
    }
}

// SECTION 3
.section3{
    margin-bottom: 40px;
    .container{
        flex-direction: column;
        padding-bottom: 15px;
        border-bottom: 1px solid #2d2d2d;
        position: relative;
        img{
            margin: 0 0 25px;
        }
        .contactBtn{
            padding: 18px 55px 18px 85px;
            font-size: 28px;
            color: #ffffff;
            font-family: 'Anton';
            position: absolute;
            right: 0;
            bottom: -35px;
            background: #433a9b;
            text-transform: uppercase;
            @media(max-width:767px){
                position: relative;
                text-align: center;
                bottom: 0;
            }
            &:before{
                content: '';
                border-left: 30px solid #191818;
                border-top: 20px solid transparent;
                border-bottom: 55px solid transparent;
                position: absolute;
                left: 0;
                top: 0;
            }
            &:after{
                content: '';
                border-right: 30px solid #191818;
                border-top: 20px solid transparent;
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }
    }
}

// SECTION 4
.section4{
    .container{
        flex-direction: column;
        h2{
            text-transform: uppercase;
            font-size: 48px;
            letter-spacing: 2px;
            @media(max-width:479px){
                font-size: 35px;
            }
        }
        .subText {
            font-size: 20px;
            font-family: 'Poppins';
            font-weight: 300;
            margin: 0 0 20px;
        }
        .testimonial{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;
            margin-top: 30px;
            li{
                width: 24%;
                text-align: center;
                margin: 0 0 35px;
                @media(max-width:991px){
                    width: 49%;
                }
                @media(max-width:991px){
                    width: 100%;
                }
                .avatar{
                    border-radius: 100%;
                    margin: 0 0 20px;
                    img{
                        width: 160px;
                        height: 160px;
                        border-radius: 100%;
                        object-fit: cover;
                    }
                }
                h3{
                    font-size: 24px;
                    text-transform: uppercase;
                }
                .subText{
                    font-size: 18px;
                    margin: 0 0 18px;
                    line-height: 1;
                    display: flex;
                    justify-content: center;
                    img{
                        margin-left: 10px;
                    }
                }
                p{
                    text-align: left;
                }
            }
        }
    }
}

// FOOTER
footer{
    padding: 25px 0;
    background: #4540a0;
    .container{
        justify-content: space-between;   
        align-items: center;   
        @media(max-width:767px){
            flex-wrap: wrap;
            justify-content: center;
        }  
        .footerLogo{
            flex-direction: column;
            display: flex;
            @media(max-width:767px){
                justify-content: center;
                margin-bottom: 20px;
                flex-basis: 100%;
                text-align: center;
            }
            img{
                width: 148px;
                margin: 0 0 7px;
                @media(max-width:767px){
                    margin: 0 auto 7px;
                }
            }
            span{
                font-size: 12px;
                color: #aaa5ff;
            }
        }
        .menu{
            display: flex;
            justify-content: space-between;
            @media(max-width:767px){
                flex-basis: 100%;
                max-width: 100%;
                margin-bottom: 14px;
                justify-content: center;
            }
            li{
                margin: 0 50px;
                @media(max-width:991px){
                    margin: 0 20px;
                }
                @media(max-width:479px){
                    margin: 0 15px;
                }
                a{
                    font-size: 14px;
                    font-family: 'Poppins';
                    color: #f7efef;
                    text-transform: uppercase;
                }
            }
        }
        .social{
            display: flex;
            li{
                margin: 0 50px;
                @media(max-width:991px){
                    margin: 0 10px;
                }
                &:first-child{
                    a{background-position: 0px 3px;}
                }
                &:nth-child(2){
                    a{background-position: -50px 3px;}
                }
                &:last-child{
                    margin-right: 0;
                    a{background-position: -100px 2px;}
                }
                a{
                    background: url('../assets/images/social.png') no-repeat 0 0;
                    width: 20px;
                    height: 20px;
                    display: block;
                }
            }
        }
    }
}