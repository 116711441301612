
.overlay {
    position: static;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: none;
    z-index: 2;
    transition: 0.3s all ease-in-out 0s;
  }
  
  .body-active {
    @media(max-width:991px) {
      height: 100vh;
      overflow: hidden;
  
      .overlay {
        display: block;
        left: 0;
      }
    }
  }
  .menubtnmob{
      padding: 0;
      background: transparent;
      box-shadow: none;
      border: 0;
      display: none;
      @media(max-width:991px){
          display: block;
      }
      img{
          width: 24px;
          height: 25px;filter: brightness(0) invert(1);

      }
  }
  .navmenu{
      .closbtn{
          position: absolute;
          top: 10px;
          right: 10px;
          padding: 0;
          border: 0;
          background: transparent;
          display: none;
          @media(max-width:991px){
              display: block;
          }
          img{
              width: 24px;
          }
      }
  }
  
 body{
          scroll-behavior: smooth;
      
  }