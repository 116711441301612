@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/Poppins-Light.woff2') format('woff2'),
      url('assets/fonts/Poppins-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/Poppins-SemiBold.woff2') format('woff2'),
      url('assets/fonts/Poppins-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Anton';
  src: url('assets/fonts/Anton-Regular.woff2') format('woff2'),
      url('assets/fonts/Anton-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('assets/fonts/SegoeUI.woff2') format('woff2'),
      url('assets/fonts/SegoeUI.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  box-sizing: border-box;
}

html{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body{
  margin: 0;
  padding: 0;
  background-color: #191818;
  font-family: 'Segoe UI';
  color: #a6a6a6;
  font-size: 16px;
  line-height: 1.4;
}

img{
  max-width: 100%;
  height: auto;
}

p{
  margin: 0 0 20px 0;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0 0 20px 0;
  font-family: 'Anton';
  color: #f2f0f0;
  font-weight: normal;
  line-height: 1;
  // font-family: 'Poppins';
  // font-weight: 300;
}

nav{
  font-family: 'Poppins';
  font-weight: 600;
  color: #ffffff;
}

ul{
  list-style: none;
  margin: 0;
  padding: 0;
}

a{
  cursor: pointer;
  color: #6258ff;
}

.container{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 15px;
  @media(max-width:1199px){
    max-width: 960px;
  }
  @media(max-width:991px){
    max-width: 720px;
  }
  @media(max-width:767px){
    max-width: 540px;
  }
  @media(max-width:575px){
    max-width: 100%;
  }
}