.pagewrapper{
    margin-top: 178px;
    padding-bottom: 52px;
    @media(max-width:991px){
        margin-top: 0;
    }
}
.pgWrapperOuter{
    &:before{
        position: static;
        content: "";
        top: 0;
        left: 0;
        background: #191818;
        width: 100%;
        z-index: 1;
        height: 150px;
        top: 0;
    }
}
a{
    text-decoration: none;
}